<template>
  <CCard>
    <CCardHeader>
      <h3>Eliminar Producto</h3>
    </CCardHeader>

    <CCardBody>

      <div class="form-group">
        <label>Producto:</label>
        <p>{{ registro.producto }}</p>
      </div>
      <div class="form-group">
        <label>Descripción:</label>
        <p>{{ registro.descripcion }}</p>
      </div>

      <div class="form-group">
        <label>Descripción corta:</label>
        <p>{{ registro.descripcion_corta }}</p>
      </div>
      <div class="form-group">
        <label>Condición producto:</label>
        <p>{{ registro.condicion_producto }}</p>
      </div>
      <div class="form-group">
        <label>Texto adj.:</label>
        <p>{{ registro.texto_adjunto }}</p>
      </div>
      <div class="form-group">
        <label>Archivo adj.:</label>
        <p>{{ registro.archivo_adjunto }}</p>
      </div>
      <div class="form-group">
        <label>idioma:</label>
        <p>{{ registro.idioma }}</p>
      </div>

      <div class="form-group">
        <button type="button" class="btn btn-danger" @click="deleted()">
          <span class="cil-trash btn-icon mr-2"></span>
          Eliminar
        </button>

        <router-link to="/ecoProductos/List">
          <CButton class="ml-2" color="secondary">
            <span class="cil-x-circle btn-icon mr-2"></span>
            Cancelar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        grupo: '',
        tipo: '',
        created_at: '',
        updated_at: '',
        delete_at: '',
        textura: '',
        color: '',
        order: '',
        codigo: '',
        idioma: '',
      }
    };
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== "0") {
        let url = "eco-productos/" + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
      }
    },
    async deleted() {

      let url = "eco-productos/" + this.id;
      let response = await httpService.delete(url);
      this.$vToastify.success("Operación realizada con éxito", "Éxito");
      this.$router.back()
    }
  },

  mounted() {
    this.getRegistro();
  }
};
</script>